.about {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    margin: 80px auto;
}

.about-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
}

.about-image {
    flex: 2;
    max-width: 50%;
    /* Increase width */
    position: relative;
    margin-right: 20px;
    margin-top: 40px;
}

.about-img {
    width: 100%;
    /* Fill container */
    min-width: 580px;
    /* Increase max size */
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 10px;
}

.about-text {
    flex: 1;
    max-width: 55%;
}

.about-text h3 {
    font-size: 22px;
    font-weight: bold;
    color: #f30202;
    margin-bottom: 10px;
}

.about-text p {
    color: #676767;
    margin-bottom: 10px;
    line-height: 1.6;
}
.pp{
    color: #676767;
        margin-bottom: 10px;
        line-height: 1.6;
}
.about-text ul {
    list-style: none;
    padding: 0;
}

.about-text ul li {
    margin-bottom: 8px;
    margin-left: 25px;
}

.about-text a {
    color: #f30202;
    font-weight: bold;
    text-decoration: none;
}

.about-text a:hover {
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 900px) {
    .about-container {
        flex-direction: column;
        align-items: center;
    }

    .about-image {
        margin-right: 100px;
        margin-bottom: 20px;
    }

    .about-text {
        max-width: 100%;
        text-align: left;
    }
        .about-img{
            min-width: 300px;
        }
}