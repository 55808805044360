.about {
    display: flex;
    flex-direction: column-reverse;
    /* For mobile: image first, content second */
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 20px;
}

.about-title {
    text-align: center;
    color: #f30202;
    margin-bottom: 15px;
}

.about-image img {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-bottom: 20px;
}

.about-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.about-section {
    display: flex;
        justify-content: space-between;
        padding: 10px;
        border-radius: 5px;
        background-color: rgba(216, 172, 85, 0.171);
   
}

.about-section ul {
    list-style: none;
    padding-left: 0;
}

.about-section.two-columns ul {
    display: flex;
    justify-content: space-between;
  
}

@media screen and (min-width: 768px) {
    .about {
        flex-direction: row;
        /* For larger screens: content and image side-by-side */
    }

    .about-content {
        max-width: 700px;
        padding-left: 20px;
    }

    .about-image img {
        margin-bottom: 0;
        max-width: 350px;
    }
}