.hero {
    width: 100%;
    min-height: 90vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: background-image 1s ease-in-out;
    position: relative;
    margin-top: 5%;
}

/* Add a dark gradient shadow */
.hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4));
    /* Dark overlay */
    z-index: 1;
}

/* Ensure text and content are above the shadow */
.hero-text,
.notice {
    position: relative;
    z-index: 2;
}

/* Optional: Add a soft shadow to the text */
.hero-text h1,
.hero-text p {
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}


.hero-text {
    text-align: center;
    max-width: 800px;
    padding-top: 150px;
}

.hero-text h1 {
    font-size: 60px;
    font-weight: 600;
}

.hero-text p {
    max-width: 700px;
    margin: 10px auto 20px;
    line-height: 1.4;
}

/* Notice Styles */
.notice {
    width: 100%;
    background-color: rgba(239, 236, 236, 0.466);
    position: absolute;
    top: 20px;
    left: 0;
    overflow: hidden;
    /* Hide overflow */
}

.notice-content {
    white-space: nowrap;
    /* Prevent wrapping */
    animation: scroll 20s linear infinite;
    /* Continuous scrolling */
    color: black;
    /* background-color: white; */
    padding: 10px 0;
    animation-play-state: running;
}

.notice-content:hover {
    animation-play-state: paused;
}

/* Animation Keyframes */
@keyframes scroll {
    0% {
        transform: translateX(100%);
        /* Start from right */
    }

    100% {
        transform: translateX(-100%);
        /* Move to left */
    }
}

/*======== media query =========*/

@media (max-width: 850px) {
    .hero-text h1 {
        font-size: 40px;
    }
}

@media (max-width: 650px) {
    .hero-text h1 {
        font-size: 30px;
        max-width: 400px;
        margin: auto;
    }

    .hero-text p {
        font-size: 14px;
        margin: 15px auto 30px;
    }
}
@media (max-width: 768px) {
    .hero {
        margin-top: 100px;
    background-size: contain;
    min-height: 40vh;
    background-color: #fff;
    }
        .notice {
            top: 0px;
        }
        .btn{
            margin-bottom: 5px;
        }
                .hero::before{
                    height: 93%;
                }
}