/* Footer.css */
html,
body {
    height: 100%;
    margin: 0;
}

body {
    display: flex;
    flex-direction: column;
}

.footer {
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid #797979;
    padding: 15px 0;
    margin-top: auto;
    /* Push the footer to the bottom */
}

.footer ul li {
    list-style: none;
    display: inline-block;
    margin-left: 20px;
}

/*======== media query =========*/

@media (max-width: 600px) {
    .footer {
        display: block;
        text-align: center;
        padding: 0px 50px;
    }

    .footer ul li {
        margin: 10px;
    }

    .footer ul {
        margin-top: 10px;
    }
}